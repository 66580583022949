import URI from 'urijs'
import {escapeRegExp, isEmpty} from 'lodash'
import validate from 'validate.js'
import {purifyHtml} from 'powtoon-commons/utils/dompurify'
import {URLPattern} from 'urlpattern-polyfill'
import {isValidEmail} from 'powtoon-commons/utils'

export const format = function (str, ...args) {
  return str.replace(/\{(\d+)\}/g, (match, number) => args[parseInt(number)])
}

export const stripHtml = function (html) {
  const div = document.createElement('div')
  div.innerHTML = purifyHtml(html)

  return div.innerText
}

export const createHash = function (text) {
  if (!text || text.length === 0) {
    return 0
  }

  let hash = 0, i, chr, len
  for (i = 0, len = text.length; i < len; i++) {
    chr = text.charCodeAt(i)
    hash = ((hash << 5) - hash) + chr
    hash |= 0
  }
  return hash
}

export const formatDashedFromCamelCase = (str) => str.replace(/[A-Z]/g, '-$&').toLowerCase()

export const getFileExtension = function (url) {
  if (!url || url.length === 0) {
    return null
  }

  const filename = URI(url).filename()

  if (!filename) {
    return null
  }

  const match = /[^.]+$/.exec(filename)

  return match ? match[0] : ''
}

export const blobToBase64 = (blob, {returnDataURL} = {returnDataURL: false}) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      const dataUrl = reader.result
      const base64 = dataUrl.split(',')[1]
      const res = returnDataURL ? dataUrl : base64
      resolve(res)
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export const getCompareFn = (value) => {
  const valueRegex = new RegExp(escapeRegExp(value), 'i')
  return str => valueRegex.test(str)
}

export const isValidUrl = (value) => {
  if (value?.startsWith('mailto:')) {
    return isValidEmail(value.substring(7))
  }

  const link = prettifyUrl(value)
  const error = getUrlError(link)
  return !error
}
const pattern = new URLPattern({
  protocol: 'http{s}?'
})

export const prettifyUrl = (url) => {
  if (url?.startsWith('mailto:')) {
    return url
  }
  return pattern.test(url) ? url : `https://${url}`
}

export const isValidLink = link => {
  if (link?.startsWith('mailto:')) {
    return isValidEmail(link.substring(7))
  }

  return isEmpty(link) || !getUrlError(link)
}

export const isValidDynamicLink = link => dynamicNameMatch.test(link)

const dynamicNameMatch = /^[a-z0-9\s\-_]+$/i

const getUrlError = value => validate({website: value}, {website: {url: true}})

export const keyValueStringMaker = ({key = '', value, separators = {}}) => {
  const {pre = '', mid = '', post = ''} = separators
  return value ? `${pre}${key}${mid}${value}${post}` : ''
}

export const capitalizeFirstLetter = (str) => str?.charAt(0).toUpperCase() + str?.slice(1)

export const getFirstWord = (str) => str.substring(0, str.indexOf(' '))

export const getBoldText = (str) => {
  if (!str || !str.length) {
    return []
  }
  const regex = /(\*[^*]+\*|[^*]+)/g
  const matches = str.match(regex)
  
  return matches.map(segment => {
    const isBold = segment.startsWith('*') && segment.endsWith('*')
    return {text: isBold ? segment.slice(1, -1) : segment, isBold}
  })
}

export function formatFileSize(size) {
  if (size < 1024) {
    return `${size} bytes`
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(2)} Kb`
  } else {
    return `${(size / (1024 * 1024)).toFixed(2)} Mb`
  }
}
